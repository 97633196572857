import React from "react"
import { Link } from "gatsby"

import Container from "../components/container/container"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO 
      title="404: Not found"
      cannonicalUrl="https://digitam.nl/404"
    />
    <section id="error-404">
      <Container type="default">
        <h1>Pagina niet gevonden</h1>
        <p>Ga terug naar de <Link to="/"><span className="highlight-blue">homepage</span></Link>.</p>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
